import * as React from "react";
import { PrimaryButton, TextField, Link } from "@fluentui/react";
import {FormattedMessage} from 'react-intl';
import ReactDOM from 'react-dom';

export default class LoginForm extends React.Component {


    render() {

        return (
            <form>
                <TextField placeholder="E-Mail" className="login-field" />
                <TextField placeholder="Password" type="password" className="login-field" />
                <div className='ms-BET-Button-Primary'>

                    <PrimaryButton
                        data-automation-id='test'
                        className="login-button"
                        onClick={() =>  this.props.updateLoadingStatus(true) }><FormattedMessage id="login.button"/></PrimaryButton>
                </div>
                <div className="ms-Grid signup-row" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6"><FormattedMessage id="login.signup.title"/></div>
                        <div className="ms-Grid-col ms-sm6"><Link href="https://developer.microsoft.com/en-us/fluentui#/controls/web/link">
                            <FormattedMessage id="login.signup.button"/>
                        </Link></div>
                    </div>
                </div>
            </form>
        );
    }
}
