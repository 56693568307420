import * as React
    from "react";
import LoginForm
    from "./LoginForm";
import LoginLoader
    from "./LoginLoader";
import UserSession
    from "../Helper/UserSession";
import Cookies from 'universal-cookie';
import { config } from '../Helper/Constants'

const cookies = new Cookies();
export default class Login extends React.Component {


    dec2hex(dec) {
        return ("0" + dec.toString(16)).substr(-2);
    }

    generateCodeVerifier() {
        var array = new Uint32Array(56 / 2);
        window.crypto.getRandomValues(array);
        return Array.from(array, this.dec2hex).join("");
    }


   setCookie(cName, cValue, expDays) {
        let date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    }
     getCookie(cName) {
        const name = cName + "=";
        const cDecoded = decodeURIComponent(document.cookie); //to be careful
        const cArr = cDecoded .split('; ');
        let res;
        cArr.forEach(val => {
            if (val.indexOf(name) === 0) res = val.substring(name.length);
        })
        return res;
    }
    constructor(props) {
        super(props);

        var codeVerifier = this.generateCodeVerifier();
        var randomState = [...Array(10 + 10)].map((value) => (Math.random() * 1000000).toString(36).replace('.', '')).join('').substring(0, 10);
        var redirectURL = encodeURIComponent(config.url.INSTANCE_URL+"/app.html#/auth");
        localStorage.setItem('codeVerifier', codeVerifier);
        localStorage.setItem('state', randomState);

        console.log("Version2.0.4");
        console.log("usersesion");
        console.log(UserSession.sessionActive());
       window.location.href = "https://accounts.iconuncle.com/oauth/authorize?response_type=code&code_challenge="+codeVerifier+"&code_challenge_method=plain&redirect_uri="+redirectURL+"&client_id="+ config.client_id+"&state="+encodeURIComponent(randomState);

    }
    render() {

        return (
            <div>
                    {this.state.isLoading ?  <LoginLoader/> :  <LoginForm updateLoadingStatus = {this.updateLoadingStatus}></LoginForm> }
                </div>
        );
    }
}
