import * as React
    from "react";
import LoginForm
    from "../Login/LoginForm";
import LoginLoader
    from "../Login/LoginLoader";
import UserSession
    from "../Helper/UserSession";

import SearchBar
    from "./SearchBar";
import TabNavigation
    from "./TabNavigation";
import {
    FormattedMessage
} from "react-intl";
import {
    Icon
} from "@fluentui/react";
import APIWrapper
    from "../Helper/APIWrapper";
import IconHistory
    from "../Helper/IconHistory";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import {
    config
} from "../Helper/Constants";
const API = 'https://app.iconuncle.com/api';
const API_OAUTH = 'https://app.iconuncle.com';
const GET_PASSWORD_ROUTE = '/procedures/execute';
const GET_USERINFO_ROUTE = '/oauth/userinfo';

export default class Account extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            session: UserSession.getSession(),
            paymentInProgress: false
        };
    }

    componentDidMount() {
    }

    openAccountSettings(e) {
        e.stopPropagation();
        e.preventDefault();
      this.getOneTimePassword((password) => {
          window.open("https://app.iconuncle.com/user?otp="+password);
      })
    }

    openSubscribePage(e) {
        e.stopPropagation();
        e.preventDefault();
      this.getOneTimePassword((password) => {
          window.open("https://app.iconuncle.com/user/payment?otp="+password);
          this.setState({
              paymentInProgress: true
          });
      })
    }
    refreshAccount(e) {
        e.stopPropagation();
        e.preventDefault();

        APIWrapper.request(API_OAUTH + GET_USERINFO_ROUTE, null, {
            "data": null,
            "meta": null
        }, (data) => {
            console.log("data");
            console.log(data);

            if (typeof data.email !== "undefined"){
                var currentSession = UserSession.getSession();
                currentSession.bookedPlan = data.bookedPlan;
                currentSession.bookedPlanExpires = data.bookedPlanExpires;
                UserSession.setSession(currentSession);
                location.reload();
            }
        }, "GET");
    }
    logout(e) {
        e.stopPropagation();
        e.preventDefault();

        UserSession.destroy();
        window.location.href = "/taskpane.html";


    }

    getOneTimePassword(callback) {

        APIWrapper.request(API + GET_PASSWORD_ROUTE, "createOneTimePassword", {
            "data": null,
            "meta": null
        }, (data) => {
           callback(data.data.password);
        });
    }


    updateSearchFilter(query, style, collection) {
        console.log(query);

        this.setState({
            queryFilter: query,
            styleFilter: style,
            collectionFilter: collection
        }, () => {
        });
    }

    render() {
        let {
            session,
            paymentInProgress
        } = this.state;
        const {onChangeLanguage} = this.props;

        return (
            <div>
                <SearchBar updateSearchFilter={this.updateSearchFilter.bind(this)} onChangeLanguage={onChangeLanguage} />
                <div className="account-wrapper">
                    {paymentInProgress ?
                        <div><br />
                            <strong>Did you finish the payment?</strong><br />Click the button to refresh your subscription status in the AddIn <DefaultButton onClick={(e) => this.refreshAccount(e)} text={
                            <span>Refresh Account</span>} allowDisabledFocus />
                        </div>
                        :
                        <div>
                            <h3>Hello {session.given_name}</h3>
                            <div>
                                <strong>Plan:</strong> {session.bookedPlan}
                            </div>
                            {session.bookedPlan === "free" ?
                                <DefaultButton onClick={(e) => this.openSubscribePage(e)} text={
                                    <span>Go Premium <Icon iconName={'NavigateExternalInline'} aria-hidden="true" /></span>} allowDisabledFocus /> :
                                <DefaultButton onClick={(e) => this.openSubscribePage(e)} text={
                                    <span>Manage Payment Plan <Icon iconName={'NavigateExternalInline'} aria-hidden="true" /></span>} allowDisabledFocus />
                            }
                            <DefaultButton onClick={(e) => this.openAccountSettings(e)} text={
                                <span>Account settings <Icon iconName={'NavigateExternalInline'} aria-hidden="true" /></span>} allowDisabledFocus />
                            <DefaultButton onClick={(e) => window.open("https://iconuncle.com/privacy-policy/")}  text={<span>Privacy Policy <Icon iconName={'NavigateExternalInline'} aria-hidden="true" /></span>} allowDisabledFocus />
                            <DefaultButton onClick={(e) => this.logout(e)}  text="Logout" allowDisabledFocus />
                        </div>
                    }
                </div>
                <TabNavigation></TabNavigation>
            </div>
        );
    }
}
