import * as React
    from "react";
import LoginForm
    from "../Login/LoginForm";
import LoginLoader
    from "../Login/LoginLoader";
import UserSession
    from "../Helper/UserSession";
import {
    PrimaryButton,
    TextField,
    Link,
    Dropdown,
    DropdownMenuItemType,
    IDropdownOption,
    IDropdownStyles,
    IconButton,
    Icon,
    ResponsiveMode,
    SearchBox
} from "@fluentui/react";
import {
    FormattedMessage,
    useIntl
} from "react-intl";

import Cookies from 'universal-cookie';
import APIWrapper
    from "../Helper/APIWrapper";
const cookies = new Cookies();


const API = 'https://app.iconuncle.com/api';
const GET_ICONS_ROUTE = '/procedures/execute';

export default class SearchBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            queryFilter: '',
            stylesFilter: null,
            collectionFilter: null,
            displayPremiumTeaser: false,
            stylesOptions: [],
            collectionsOptions: []
        };

        this.updateStylesValue = this.updateStylesValue.bind(this);
        this.updateCollectionValue = this.updateCollectionValue.bind(this);
        this.hidePremiumTeaser = this.hidePremiumTeaser.bind(this)

    }

    componentDidMount() {
        this.loadStyles();
        this.loadCollections();


        if (localStorage.getItem('hidePremiumTeaser') === null && UserSession.getSession().bookedPlan === "free"){
            this.state.displayPremiumTeaser = true;
        }
    }


    updateSearchValue(evt) {
        if (typeof evt === "undefined"){
          return false;
        }

        const val = evt.target.value;
        this.setState({
            queryFilter: val
        });
    }

    updateStylesValue(event, option, index) {
        this.setState({
            stylesFilter: option.key
        }, () => {
            this.props.updateSearchFilter(this.state.queryFilter, this.state.stylesFilter, this.state.collectionFilter);
        });
    }

    updateCollectionValue(event, option, index) {
        this.setState({
            collectionFilter: option.key
        }, () => {
            this.props.updateSearchFilter(this.state.queryFilter, this.state.stylesFilter, this.state.collectionFilter);
        });
    }


    loadStyles() {

        APIWrapper.request(API + GET_ICONS_ROUTE, "listStyles", {"data": null, "meta": null}, (data) => {

            var stylesArray = [];

            stylesArray.push({
                key: null,
                text: "All Styles",
                data: {
                    isPremium: false
                }
            });
            data.data.items.forEach(function (item, index) {
                stylesArray.push({
                    key: item.id,
                    text: item.name["en"],
                    data: {
                        isPremium: false
                    }
                });
            });

            this.setState({
                stylesOptions: stylesArray
            });
        });
    }

    loadCollections() {

        APIWrapper.request(API + GET_ICONS_ROUTE, "listCollections", {"data": null, "meta": null}, (data) => {

            console.log(data.data);

            var collectionArray = [];
            collectionArray.push({
                key: null,
                text: "All Collections",
                data: {
                    isPremium: false
                }
            });
            data.data.items.forEach(function (item, index) {
                collectionArray.push({
                    key: item.id,
                    text: item.name["en"],
                    data: {
                        isPremium: false
                    }
                });
            });

            this.setState({
                collectionsOptions: collectionArray
            });
        });

    }

    hidePremiumTeaser = () => {
        localStorage.setItem("hidePremiumTeaser", true);
        this.setState({displayPremiumTeaser : false })
    };

    updateFilter() {
        this.props.updateSearchFilter(this.state.queryFilter, this.state.stylesFilter, this.state.collectionFilter);
    }

    render() {
        const dropdownStyles = {
            dropdown: {
                width: "100%",
            },
            title: {
                background: '#727272',
                border: "none",
                color: "#fff"
            }
        };
        const filterDropdownStyles = {
            dropdown: {
                width: "100%",
            },
            caretDown: {
                color: "white"
            },
            dropdownItem: {
                width: "100%",

                selectors: {
                    ":hover": {
                        backgroundColor: "#F8F8F8"
                    }
                },
            },
            title: {
                selectors: {
                    ":hover": {
                        backgroundColor: "#fff",
                        color: "red",
                    }
                },
                background: '#0078d4',
                border: "none",
                color: "#fff"
            }
        };

        let languageOptions = [
            {
                key: 'fruitsHeader',
                text: 'Languages',
                itemType: DropdownMenuItemType.Header
            },
     /*       {
                key: 'de',
                text: 'Deutsch'
            }, */
            {
                key: 'en',
                text: 'Englisch'
            }
        ];

        const onRenderOption = (option) => {

            return (
                <div className="filter-option">
                    <span>{option.text}</span> {option.data && option.data.isPremium && (
                    <span className="premium-badge">Premium</span>
                )}
                </div>
            );
        };

        const onRenderLanguageSelectPlaceholder = (props) => {
            return (
                <div className="dropdownExample-placeholder">
                    <span>{props.placeholder}</span>
                    <Icon className="language-select-caret" iconName={'CaretDown8'} aria-hidden="true" />
                </div>
            );
        };

        const { onChangeLanguage } = this.props;

        const onChange = (event, item) => {
            onChangeLanguage(item.key);
        };


        let currentLang = "en";
        if (typeof cookies.get('language') !== "undefined"){
            currentLang = cookies.get('language');
        }

        var leftControl =
            <Dropdown onRenderCaretDown={() => {
            }} styles={dropdownStyles} onChange={onChange} onRenderTitle={options => <span>{options[0].text.substring(0, 2).toUpperCase()}</span>} defaultSelectedKey={currentLang} onRenderPlaceholder={onRenderLanguageSelectPlaceholder} options={languageOptions} />

        if (this.props.leftControl === "back-button") {
            leftControl =
                <a className="back-button" href="#icons"><Icon iconName={'ChevronLeftMed'} aria-hidden="true" /></a>
        }



            return (
            <div className="icon-search">
                {  this.state.displayPremiumTeaser ?
                    <div className="premium-teaser">
                        <div>
                            <img src="assets/icon-white.svg" alt="" /></div>
                        <div>
                            <FormattedMessage id="subscription.premiumTeaser" />
                        </div>
                        <div>
                            <a  onClick={this.hidePremiumTeaser} className="close-option">
                                <Icon iconName={'StatusCircleErrorX'} aria-hidden="true" />
                            </a>
                        </div>
                    </div>
                    : ""
                }
                <div className="search-bar">
                    <div className="language-select ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm3">
                                {leftControl}
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid signup-row" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm10">
                                <FormattedMessage id="icons.filter.search" defaultMessage="search">
                                    {placeholder =>
                                        <SearchBox placeholder={placeholder} className="icon-search-field" onChange={evt => this.updateSearchValue(evt)} showIcon={false} onSearch={newValue => this.updateFilter()} disableAnimation />
                                    }
                                </FormattedMessage>
                            </div>
                            <div className="ms-Grid-col ms-sm2">
                                <PrimaryButton className="search-button" onClick={() => this.updateFilter()} iconProps={{iconName: 'Search'}} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filter-bar">
                    <div className="ms-Grid signup-row" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm5">
                                <FormattedMessage id="icons.filter.styles" defaultMessage="search">
                                    {placeholder =>
                                        <Dropdown styles={filterDropdownStyles} responsiveMode={ResponsiveMode.unknown} onRenderOption={onRenderOption} onChange={this.updateStylesValue} placeholder={placeholder} options={this.state.stylesOptions} />
                                    }
                                </FormattedMessage>
                            </div>
                            <div className="ms-Grid-col ms-sm7">
                                <FormattedMessage id="icons.filter.collections" defaultMessage="search">
                                    {placeholder =>
                                        <Dropdown styles={filterDropdownStyles} responsiveMode={ResponsiveMode.unknown} onRenderOption={onRenderOption} onChange={this.updateCollectionValue} placeholder={placeholder} options={this.state.collectionsOptions} />
                                    }
                                </FormattedMessage>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
