import * as React
    from "react";
import LoginForm
    from "./LoginForm";
import LoginLoader
    from "./LoginLoader";
import UserSession
    from "../Helper/UserSession";
import { config } from '../Helper/Constants'

import SearchBar
    from "../LoggedIn/SearchBar";
import TabNavigation
    from "../LoggedIn/TabNavigation";
import {Icon} from "@fluentui/react";
import {FormattedMessage} from "react-intl";
import { HashRouter, Route,Redirect } from 'react-router-dom'

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const API = 'https://accounts.iconuncle.com';
const OAUTH_ROUTE = '/oauth/token';

export default class Auth extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
        };
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code')
        const state = urlParams.get('state')


        if (localStorage.getItem('state') === state){
            console.log("State valid");
            var redirectURL = config.url.INSTANCE_URL+"/app.html#/auth";


            var details = {
                'code': code,
                'grant_type': 'authorization_code',
                'client_id': config.client_id,
                'code_verifier':  localStorage.getItem('codeVerifier'),
                'redirect_uri': redirectURL
            };

            var formBody = [];
            for (var property in details) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(details[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");


            fetch(API + OAUTH_ROUTE, {
                method: 'POST',
                body: formBody,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);

                    var session = data;
                    session.last_refreshed = Math.round(Date.now() / 1000);

                    UserSession.setSession(session);

                    this.setState({
                        loggedIn: true
                    });
                    console.log("got icon");
                });
        }

    }


    render() {
        const { title, isOfficeInitialized, onChangeLanguage } = this.props;

        return (
            <div>
                {!this.state.loggedIn ?  <LoginLoader/> : <Redirect to="/icons" /> }
            </div>
        );
    }
}

