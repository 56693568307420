import * as React
    from "react";
import LoginForm
    from "../Login/LoginForm";
import LoginLoader
    from "../Login/LoginLoader";
import UserSession
    from "../Helper/UserSession";

import SearchBar
    from "../LoggedIn/SearchBar";
import {
    Icon,
    PrimaryButton
} from "@fluentui/react";
import { NavLink } from 'react-router-dom'
import {FormattedMessage} from "react-intl";

export default class TabNavigation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            icons: [],
            session: UserSession.getSession(),
        };

    }

    render() {
        const {
            session
        } = this.state;
        return (
            <ul className="tab-navigation">
                <li>
                    <NavLink
                        to="/icons"
                        exact
                        activeClassName='active'
                    >
                        {session.bookedPlan !== "plus" ?
                            <span><Icon className="tab-navigation-icon" iconName={'GiftboxOpen'} aria-hidden="true" />
                           Free Icons</span>
                            : <span><Icon className="tab-navigation-icon" iconName={'DateTime2'} aria-hidden="true" />
                           <FormattedMessage id="navigation.newest"/></span>
                        }

                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/favorites"
                        activeClassName='active'
                    >
                        <Icon className="tab-navigation-icon" iconName={'Heart'} aria-hidden="true" />
                        <FormattedMessage id="navigation.favorites"/>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                    to="/history"
                    activeClassName='active'
                    >
                        <Icon className="tab-navigation-icon" iconName={'History'} aria-hidden="true" />
                        <FormattedMessage id="navigation.history"/>
                </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/account"
                        activeClassName='active'
                    >
                        <Icon className="tab-navigation-icon" iconName={'UserOptional'} aria-hidden="true" />
                        Account
                    </NavLink>
                </li>
            </ul>
        );
    }
}
