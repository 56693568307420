const prod = {
    client_id: "app_iconuncle_com",
    url: {
        INSTANCE_URL: "https://app.iconuncle.com"
    }};
const dev = {
    client_id: "local_app",
    url: {
        INSTANCE_URL: "https://localhost:3000"
    }
};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;