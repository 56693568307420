import App
    from "./components/App";
import {AppContainer} from "react-hot-loader";
import {initializeIcons} from "@fluentui/font-icons-mdl2";
import * as React
    from "react";
import * as ReactDOM
    from "react-dom";
/* global document, Office, module, require */
import {IntlProvider} from 'react-intl';
import translations_de from "../../locales/de/translation.json";
import translations_en from "../../locales/en/translation.json";
import "./taskpane.css"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const translations = {
    //'de': translations_de,
    'en': translations_en
};
let i18nConfig = {
    locale: 'en',
};


initializeIcons();


const title = "Icon Uncle";

let onChangeLanguage = (lang) => {
    i18nConfig.locale = lang;

    cookies.set("language", lang, {
        maxAge: 60 * 60 * 24 * 365,
        sameSite: true
    });

    render(App);
}

const render = (Component) => {

    if (typeof cookies.get('language') !== "undefined"){
        i18nConfig.locale = cookies.get('language');
    }else{
        var userLang = navigator.language || navigator.userLanguage;
        var userLangShort = userLang.substring(0, 2);

        if (userLangShort in translations){
            i18nConfig.locale = userLangShort;
            cookies.set("language", userLangShort, {
                maxAge: 60 * 60 * 24 * 365,
                sameSite: true
            });
        }
    }

    ReactDOM.render(
        <IntlProvider locale={i18nConfig.locale} defaultLocale={i18nConfig.locale} messages={translations[i18nConfig.locale]}>
            <AppContainer>
                <Component title={title}  onChangeLanguage={onChangeLanguage} />
            </AppContainer>
        </IntlProvider>,
        document.getElementById('container')
    );
};

    render(App);

/* Initial render showing a progress bar */
render(App);

if (module.hot) {
    module.hot.accept("./components/App", () => {
        const NextApp = require("./components/App").default;
        render(NextApp);
    });
}
