import * as React
    from "react";
import LoginForm
    from "../Login/LoginForm";
import LoginLoader
    from "../Login/LoginLoader";
import UserSession
    from "../Helper/UserSession";

import SearchBar
    from "./SearchBar";
import TabNavigation
    from "./TabNavigation";
import IconSuggestions
    from "./IconSuggestions";

import APIWrapper
    from "../Helper/APIWrapper";
import FlashMessage
    from 'react-flash-message'
import {
    FormattedMessage
} from "react-intl";
import IconHistory
    from "../Helper/IconHistory";
import {
    NavLink
} from "react-router-dom";


const API = 'https://accounts.iconuncle.com/api';
const GET_ICONS_ROUTE = '/procedures/execute';
const GET_PASSWORD_ROUTE = '/procedures/execute';

export default class IconDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isCalloutVisible: false,
            didLoad: false,
            showAddedToFavs: false,
            session: UserSession.getSession(),
            showRemovedFromFavs: false,
            iconId: props.icon,
            showSidebar: props.showSidebar,
            loadedIcon: null,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loadedIcon: nextProps.icon,
            showSidebar: nextProps.showSidebar,
        }, () => {
            console.log(nextProps.icon);

        });
    }


    downloadIcon(e, icon) {
        e.stopPropagation();
        e.preventDefault();

        IconHistory.addToHistory(icon)

        this.getOneTimePassword((password) => {
            window.location = icon.url + "?otp=" + password;
        })
    }

    loadImage(url) {
        return new Promise((resolve, reject) => {
            const img = new Image(); // alt: document.createElement('img')
            img.addEventListener('load', () => resolve(img));
            img.addEventListener('error', (err) => reject(err));
            img.src = url;
        });
    }

    handleSVG(svgMarkup, name) {
        var img = new Image();
        var self = this;
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgMarkup, "image/svg+xml");

        // set the width and height attributes to 1080px
        svgDoc.documentElement.setAttribute("width", "1080px");
        svgDoc.documentElement.setAttribute("height", "1080px");

        // convert the modified XML document back to a string
        const serializer = new XMLSerializer();
        const updatedSVGMarkup = serializer.serializeToString(svgDoc);

        img.src = "data:image/svg+xml," + encodeURIComponent(updatedSVGMarkup);
        document.body.appendChild(img);
        img.onload = function() {
            var canvas = document.createElement("canvas");
            canvas.width = 1080;
            canvas.height = 1080;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            var imgUrl = canvas.toDataURL("image/png");

            // Create a Blob object from the image data
            var blob = self.dataURItoBlob(imgUrl);

            // Create a URL object from the Blob
            var url = URL.createObjectURL(blob);

            // Create a link element
            var link = document.createElement("a");
            link.download = name+".png";
            link.href = url;
            link.click();

            // Revoke the object URL after the download
            setTimeout(function() {
                URL.revokeObjectURL(url);
            }, 100);
            document.body.removeChild(img);
        }
    }

// Helper function to convert a data URI to a Blob
    dataURItoBlob(dataURI) {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }



    async downloadIconAsPNG(e, icon) {
        e.stopPropagation();
        e.preventDefault();

        IconHistory.addToHistory(icon)

        const scale = window.devicePixelRatio * 2;
        var fileName = icon.name.en;
        fileName = fileName.replace(/[^a-zA-Z0-9 ]/g, "");

        // Replace spaces with underscores
        fileName = fileName.replace(/\s/g, "_");

        fileName = "icon-"+fileName+"-IconUncle";
        this.getOneTimePassword((password) => {
            console.log("svg");
            console.log(icon.url + "?otp=" + password);

            this.handleSVG(icon.content.replace(/width="\d+"|height="\d+"/gi, ''), fileName);
        })

    }

    getOneTimePassword(callback) {

        APIWrapper.request(API + GET_PASSWORD_ROUTE, "createOneTimePassword", {
            "data": null,
            "meta": null
        }, (data) => {
            callback(data.data.password);
        });
    }

    closeDetail() {
        this.setState({
            "showSidebar": false,
            "loadedIcon": null
        })
        this.props.closeDetailSidebar();
    }

    updateFavoriteStatus(type) {

        APIWrapper.request(API + GET_ICONS_ROUTE, type, {
            "data": {
                "icon": this.state.loadedIcon.id
            },
            "meta": null
        }, (data) => {

            var icon = this.state.loadedIcon;
            if (type === "addFavoriteIcon") {
                icon.isFavorite = true;
                this.setState({
                    showAddedToFavs: true,
                    loadedIcon: icon
                });
            } else {
                icon.isFavorite = false;
                this.setState({
                    showRemovedFromFavs: true,
                    loadedIcon: icon
                });
            }

        });
    }


    render() {
        var isCalloutVisible = false;

        const styles = {
            background: "#666666"
        };
        const {
            loadedIcon,
            showSidebar,
            didLoad,
            session
        } = this.state;

        let favClass = "";
        let favUpdateRoute = "addFavoriteIcon";

        if (loadedIcon !== null) {

            if (loadedIcon.isFavorite) {
                favClass = "filled";
                favUpdateRoute = "removeFavoriteIcon";
            }

            var svg = loadedIcon.content;
            var downloadURL = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);
        }

        return (
            <aside className={`icon-detail-sidebar ${showSidebar ? "slide-out" : ""}`}>
                {loadedIcon == null ?
                    <div>Loading...</div>
                    :
                    <div>
                        <a onClick={() => this.closeDetail()} className="close-sidebar"></a>
                        <h1 id="icon-detail-showcase">{loadedIcon.name.en}</h1>
                        <div className="icon-showcase">
                            <span dangerouslySetInnerHTML={{__html: loadedIcon.content.replace(/width="\d+"|height="\d+"/gi, '')}} />
                        </div>
                        <div className="dummy-icon">
                            <span dangerouslySetInnerHTML={{__html: loadedIcon.content.replace(/width="\d+"|height="\d+"/gi, '')}} />
                        </div>

                        <div className="icon-actions">
                            <a onClick={(e) => this.downloadIcon(e, loadedIcon)} className="btn btn-primary btn-lg download">Download <span className="extension">SVG</span></a>
                            <a onClick={(e) => this.downloadIconAsPNG(e, loadedIcon)} className="btn btn-primary btn-lg download">Download <span className="extension">PNG</span></a>
                            {session.bookedPlan !== "pro" ? ""  :
                                <button type="button" className={`${favClass} btn btn-primary btn-lg favorite`} onClick={e => {
                                    this.updateFavoriteStatus(favUpdateRoute)
                                }}></button>
                            }
                        </div>
                        {this.state.showAddedToFavs &&
                            <div>
                                <FlashMessage duration={5000}>
                                    <div className="alert alert-success" role="alert">
                                        <FormattedMessage id="icon.addedToFavorites" />
                                    </div>
                                </FlashMessage>
                            </div>
                        } {this.state.showRemovedFromFavs &&
                        <div>
                            <FlashMessage duration={5000}>
                                <div className="alert alert-success" role="alert">
                                    <FormattedMessage id="icon.removedFromFavorites" />
                                </div>
                            </FlashMessage>
                        </div>
                    }
                        <IconSuggestions icon={loadedIcon} openCollection={this.props.openCollection} showIcon={this.props.showIcon}></IconSuggestions>
                    </div>
                }
            </aside>
        );
    }
}
