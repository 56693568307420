import * as React
    from "react";
import LoginForm
    from "../Login/LoginForm";
import LoginLoader
    from "../Login/LoginLoader";
import UserSession
    from "../Helper/UserSession";
import {
    PrimaryButton,
    TextField,
    Link,
    Dropdown,
    DropdownMenuItemType,
    IDropdownOption,
    IDropdownStyles,
    IconButton,
    Icon,
    ResponsiveMode,
    SearchBox
} from "@fluentui/react";
import {
    FormattedMessage,
    useIntl
} from "react-intl";

import Cookies
    from 'universal-cookie';
import APIWrapper
    from "../Helper/APIWrapper";

const cookies = new Cookies();


const API = 'https://accounts.iconuncle.com/api';
const GET_ICONS_ROUTE = '/procedures/execute';
const GET_PASSWORD_ROUTE = '/procedures/execute';

export default class Sidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            queryFilter: '',
            queryProcedure: 'searchIcons',
            stylesFilter: "1ed075ea-7d9d-61f8-9665-5f415c0489fd",
            collectionFilter: "default",
            displayPremiumTeaser: false,
            showFiltersMobile: false,
            session: UserSession.getSession(),
            stylesOptions: [],
            collectionsOptions: []
        };

        this.updateStylesValue = this.updateStylesValue.bind(this);
        this.updateCollectionValue = this.updateCollectionValue.bind(this);
        this.hidePremiumTeaser = this.hidePremiumTeaser.bind(this)

    }

    componentDidMount() {
        this.loadStyles();
        this.loadCollections();


        if (localStorage.getItem('hidePremiumTeaser') === null && UserSession.getSession().bookedPlan === "free") {
            this.state.displayPremiumTeaser = true;
        }
    }

    openAccountSettings(e) {
        e.stopPropagation();
        e.preventDefault();
        const win = window.open('about:blank');

        this.getOneTimePassword((password) => {
            win.location = "https://accounts.iconuncle.com/user?otp=" + password;
        })
    }

    logout(e) {
        e.stopPropagation();
        e.preventDefault();

        UserSession.destroy();
        window.location.href = "/app.html";
    }

    getOneTimePassword(callback) {

        APIWrapper.request(API + GET_PASSWORD_ROUTE, "createOneTimePassword", {
            "data": null,
            "meta": null
        }, (data) => {
            callback(data.data.password);
        });
    }

    openSubscribePage(e) {
        e.stopPropagation();
        e.preventDefault();
        const win = window.open('about:blank');

        this.getOneTimePassword((password) => {
            win.location = "https://accounts.iconuncle.com/user/payment?otp=" + password;

            this.setState({
                paymentInProgress: true
            });
        })
    }

    updateSearchValue(evt) {
        if (typeof evt === "undefined") {
            return false;
        }

        const val = evt.target.value;
        this.setState({
            queryFilter: val,
            collectionFilter: "default"
        });
    }

    updateStylesValue(newValue) {
        this.setState({
            stylesFilter: newValue
        }, () => {
            this.props.updateSearchFilter(this.state.queryProcedure, this.state.queryFilter, this.state.stylesFilter, this.state.collectionFilter);
        });
    }

    updateCollectionValue(newValue) {
        this.setState({
            collectionFilter: newValue,
            queryFilter: ""
        }, () => {
            this.props.updateSearchFilter(this.state.queryProcedure, this.state.queryFilter, this.state.stylesFilter, this.state.collectionFilter);
        });
    }

    updateProcedure(procedure) {
        var collection = this.state.collectionFilter;

        if (procedure === "showIconHistory" || procedure === "listFavoriteIcons") {
            collection = null;
        }
        this.setState({
            queryProcedure: procedure,
            collectionFilter: collection
        }, () => {
            this.props.updateSearchFilter(this.state.queryProcedure, this.state.queryFilter, this.state.stylesFilter, this.state.collectionFilter);
        });
    }


    loadStyles() {

        APIWrapper.request(API + GET_ICONS_ROUTE, "listStyles", {
            "data": null,
            "meta": null
        }, (data) => {

            var stylesArray = [];


            data.data.items.forEach(function (item, index) {
                stylesArray.push({
                    key: item.id,
                    text: item.name["en"],
                    data: {
                        isPremium: false
                    }
                });
            });

            this.setState({
                stylesOptions: stylesArray
            });
        });
    }

    loadCollections() {

        APIWrapper.request(API + GET_ICONS_ROUTE, "listCollections", {
            "data": null,
            "meta": null
        }, (data) => {

            console.log(data.data);

            var collectionArray = [];
            collectionArray.push({
                key: "default",
                text: "All Collections",
                data: {
                    isPremium: false
                }
            });
            data.data.items.forEach(function (item, index) {
                collectionArray.push({
                    key: item.id,
                    text: item.name["en"],
                    data: {
                        isPremium: false
                    }
                });
            });

            this.setState({
                collectionsOptions: collectionArray
            });
        });

    }

    hidePremiumTeaser = () => {
        localStorage.setItem("hidePremiumTeaser", true);
        this.setState({displayPremiumTeaser: false})
    };

    updateFilter() {
        this.props.updateSearchFilter(this.state.queryProcedure, this.state.queryFilter, this.state.stylesFilter, this.state.collectionFilter);
    }

    render() {
        const dropdownStyles = {
            dropdown: {
                width: "100%",
            },
            title: {
                background: '#727272',
                border: "none",
                color: "#fff"
            }
        };
        const filterDropdownStyles = {
            dropdown: {
                width: "100%",
            },
            caretDown: {
                color: "white"
            },
            dropdownItem: {
                width: "100%",

                selectors: {
                    ":hover": {
                        backgroundColor: "#F8F8F8"
                    }
                },
            },
            title: {
                selectors: {
                    ":hover": {
                        backgroundColor: "#fff",
                        color: "red",
                    }
                },
                background: '#0078d4',
                border: "none",
                color: "#fff"
            }
        };

        let languageOptions = [
            {
                key: 'fruitsHeader',
                text: 'Languages',
                itemType: DropdownMenuItemType.Header
            },
            /*       {
                       key: 'de',
                       text: 'Deutsch'
                   }, */
            {
                key: 'en',
                text: 'Englisch'
            }
        ];

        const onRenderOption = (option) => {

            return (
                <div className="filter-option">
                    <span>{option.text}</span> {option.data && option.data.isPremium && (
                    <span className="premium-badge">Premium</span>
                )}
                </div>
            );
        };

        const onRenderLanguageSelectPlaceholder = (props) => {
            return (
                <div className="dropdownExample-placeholder">
                    <span>{props.placeholder}</span>
                    <Icon className="language-select-caret" iconName={'CaretDown8'} aria-hidden="true" />
                </div>
            );
        };

        const {onChangeLanguage} = this.props;
        const {
            queryProcedure,
            session,
            collectionsOptions,
            stylesOptions,
            showFiltersMobile
        } = this.state;

        const onChange = (event, item) => {
            onChangeLanguage(item.key);
        };


        let currentLang = "en";
        if (typeof cookies.get('language') !== "undefined") {
            currentLang = cookies.get('language');
        }

        var leftControl =
            <Dropdown onRenderCaretDown={() => {
            }} styles={dropdownStyles} onChange={onChange} onRenderTitle={options =>
                <span>{options[0].text.substring(0, 2).toUpperCase()}</span>} defaultSelectedKey={currentLang} onRenderPlaceholder={onRenderLanguageSelectPlaceholder} options={languageOptions} />

        if (this.props.leftControl === "back-button") {
            leftControl =
                <a className="back-button" href="#icons"><Icon iconName={'ChevronLeftMed'} aria-hidden="true" /></a>
        }

        const getSubscriptionName = (key) => {
            const subscriptions = [{ key: 'plus', name: 'Pro' }, {key: 'one_time', name: 'OneTime'}];
            const subscription = subscriptions.find(sub => sub.key === key);
            return subscription ? subscription.name : key;
        }


        return (
            <div  className="sidebar-wraooer">
                <aside className="sidebar">
                    <div className="desktop-sidebar-wrapper">
                        <div className="brand-name">
                            <img alt="IconUncle Logo" src={require("../../../../assets/io-logo.png")} />
                            <div>
                                <span>IconUncle</span>
                                {session.bookedPlan === "free" ?
                                    <span className="plan-badge">Free <a href="#" onClick={(e) => this.openSubscribePage(e)}>Upgrade now</a></span>

                                    : <span className="plan-badge"><strong>{ getSubscriptionName(session.bookedPlan) }</strong></span>
                                }
                            </div>
                            <div className="account-controls">
                                <a href="#" onClick={(e) => this.openAccountSettings(e)} className="account-button account"></a>
                                <a href="#" onClick={(e) => this.logout(e)} className="account-button logout"></a>
                            </div>
                        </div>
                        <div className="sidebar-control search-field">
                            <div className="btn-group">
                                <button className="btn btn-primary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    EN
                                </button>
                                <div className="dropdown-menu">
                                    ...
                                </div>
                            </div>
                            <div className="input-group">
                                <input type="search" value={this.state.queryFilter} className="form-control" onKeyDown={event => {
                                    if (event.key === 'Enter') {
                                        this.updateFilter()
                                    }
                                }} onClick={evt => this.updateSearchValue(evt)} onChange={evt => this.updateSearchValue(evt)} placeholder="Search Icon" />
                                <button className="btn btn-outline-secondary search-button" onClick={newValue => this.updateFilter()} type="button"></button>
                            </div>
                        </div>
                        <div className="sidebar-control">
                            <h3>Sort icons</h3>
                            <ul className="list-group">
                                {session.bookedPlan === "free" ?
                                    <li onClick={() => this.updateProcedure("searchIcons")} className={`list-group-item sort-filter list-group-item-action ${queryProcedure == "searchIcons" ? "active" : ""}`}>
                                        <svg version="1.1" viewBox="0 0 64 64">
                                            <path d="M56,17h-15.136c2.803,-2.19 3.876,-5.412 2.425,-7.589c-0.784,-1.176 -2.156,-1.824 -3.863,-1.824c-1.558,0 -3.239,0.549 -4.734,1.546c-1.118,0.745 -2.044,1.683 -2.691,2.708c-0.647,-1.026 -1.573,-1.964 -2.691,-2.709c-1.496,-0.997 -3.177,-1.546 -4.734,-1.546c-1.708,0 -3.08,0.647 -3.863,1.824c-0.784,1.176 -0.854,2.691 -0.198,4.268c0.515,1.236 1.457,2.386 2.657,3.322h-15.172c-0.552,0 -1,0.448 -1,1v8c0,0.552 0.448,1 1,1h5v29c0,0.553 0.448,1 1,1h36c0.553,0 1,-0.447 1,-1v-29h5c0.553,0 1,-0.448 1,-1v-8c0,-0.552 -0.447,-1 -1,-1Zm-22.712,-3.322c0.449,-1.077 1.341,-2.1 2.513,-2.88c1.171,-0.781 2.458,-1.21 3.625,-1.21c0.589,0 1.658,0.121 2.199,0.933c0.901,1.353 -0.242,3.766 -2.496,5.269c-1.171,0.78 -2.459,1.21 -3.626,1.21c-0.589,0 -1.657,-0.121 -2.198,-0.934c-0.542,-0.811 -0.243,-1.844 -0.017,-2.388Zm-8.416,2.112c-1.171,-0.781 -2.063,-1.804 -2.512,-2.881c-0.227,-0.543 -0.526,-1.577 0.016,-2.389c0.541,-0.812 1.61,-0.933 2.199,-0.933c1.167,0 2.454,0.43 3.625,1.21c1.171,0.78 2.063,1.804 2.512,2.881c0.227,0.543 0.526,1.577 -0.016,2.389c-0.541,0.812 -1.61,0.933 -2.199,0.933c-1.167,0 -2.455,-0.43 -3.625,-1.21Zm-9.872,11.21h12v28h-12v-28Zm14,28v-28h6v28h-6Zm20,0h-12v-28h12v28Zm6,-30h-46v-6h46v6Z" fill="#000"></path>
                                        </svg>
                                        Free Icons
                                    </li>
                                    :
                                    <li onClick={() => this.updateProcedure("searchIcons")} className={`list-group-item sort-filter list-group-item-action ${queryProcedure == "searchIcons" ? "active" : ""}`} aria-current="true">
                                        <svg version="1.1" viewBox="0 0 64 64">
                                            <g fill="#000">
                                                <path d="M53.5,11h-4.5v-3c0,-0.552 -0.447,-1 -1,-1c-0.553,0 -1,0.448 -1,1v3h-30v-3c0,-0.552 -0.448,-1 -1,-1c-0.552,0 -1,0.448 -1,1v3h-4.5c-1.93,0 -3.5,1.57 -3.5,3.5v39c0,1.93 1.57,3.5 3.5,3.5h43c1.93,0 3.5,-1.57 3.5,-3.5v-39c0,-1.93 -1.57,-3.5 -3.5,-3.5Zm-43,2h4.5v3c0,0.552 0.448,1 1,1c0.552,0 1,-0.448 1,-1v-3h30v3c0,0.552 0.447,1 1,1c0.553,0 1,-0.448 1,-1v-3h4.5c0.827,0 1.5,0.673 1.5,1.5v6.5h-46v-6.5c0,-0.827 0.673,-1.5 1.5,-1.5Zm43,42h-43c-0.827,0 -1.5,-0.673 -1.5,-1.5v-30.5h46v30.5c0,0.827 -0.673,1.5 -1.5,1.5Z"></path>
                                                <path d="M32,27c-6.617,0 -12,5.383 -12,12c0,6.617 5.383,12 12,12c6.617,0 12,-5.383 12,-12c0,-6.617 -5.383,-12 -12,-12Zm0,22c-5.514,0 -10,-4.486 -10,-10c0,-5.514 4.486,-10 10,-10c5.514,0 10,4.486 10,10c0,5.514 -4.486,10 -10,10Z"></path>
                                                <path d="M35,38h-2v-4c0,-0.553 -0.448,-1 -1,-1c-0.552,0 -1,0.447 -1,1v5c0,0.553 0.448,1 1,1h3c0.553,0 1,-0.447 1,-1c0,-0.553 -0.447,-1 -1,-1Z"></path>
                                            </g>
                                        </svg>
                                        Newest
                                    </li>
                                }
                                <li onClick={() => this.updateProcedure("showIconHistory")} className={`list-group-item sort-filter list-group-item-action ${queryProcedure == "showIconHistory" ? "active" : ""} ${session.bookedPlan !== "plus" ? "disabled" : ""}`}>
                                    <svg version="1.1" viewBox="0 0 64 64">
                                        <g fill="#000">
                                            <path d="M31.293,22.707c0.195,0.195 0.451,0.293 0.707,0.293c0.256,0 0.512,-0.098 0.707,-0.293c0.391,-0.391 0.391,-1.023 0,-1.414l-3.293,-3.293l3.293,-3.293c0.391,-0.391 0.391,-1.023 0,-1.414c-0.391,-0.391 -1.023,-0.391 -1.414,0l-4,4c-0.391,0.391 -0.391,1.023 0,1.414l4,4Z"></path>
                                            <path d="M31,32v8c0,0.553 0.448,1 1,1c0.552,0 1,-0.447 1,-1v-7.586l3.707,-3.707c0.391,-0.391 0.391,-1.023 0,-1.414c-0.391,-0.391 -1.023,-0.391 -1.414,0l-4,4c-0.188,0.187 -0.293,0.442 -0.293,0.707Z"></path>
                                            <path d="M32,17c-0.552,0 -1,0.448 -1,1c0,0.552 0.448,1 1,1c7.168,0 13,5.832 13,13c0,7.168 -5.832,13 -13,13c-7.168,0 -13,-5.832 -13,-13c0,-0.552 -0.448,-1 -1,-1c-0.552,0 -1,0.448 -1,1c0,8.271 6.729,15 15,15c8.271,0 15,-6.729 15,-15c0,-8.271 -6.729,-15 -15,-15Z"></path>
                                            <path d="M32,7c-13.785,0 -25,11.215 -25,25c0,13.785 11.215,25 25,25c13.785,0 25,-11.215 25,-25c0,-13.785 -11.215,-25 -25,-25Zm0,48c-12.682,0 -23,-10.317 -23,-23c0,-12.682 10.318,-23 23,-23c12.683,0 23,10.318 23,23c0,12.683 -10.317,23 -23,23Z"></path>
                                        </g>
                                    </svg>
                                    History
                                </li>
                                <li onClick={() => this.updateProcedure("listFavoriteIcons")} className={`list-group-item sort-filter list-group-item-action ${queryProcedure == "listFavoriteIcons" ? "active" : ""}  ${session.bookedPlan !== "plus" ? "disabled" : ""}`}>
                                    <svg version="1.1" viewBox="0 0 64 64">
                                        <g fill="#000">
                                            <path d="M52.506,33.051c2.455,-2.456 3.808,-5.72 3.808,-9.193c0,-3.473 -1.353,-6.737 -3.808,-9.192c-2.455,-2.455 -5.72,-3.808 -9.192,-3.808c-3.472,0 -6.737,1.352 -9.192,3.808l-2.122,2.121l-2.121,-2.121c-2.456,-2.456 -5.72,-3.808 -9.192,-3.808c-3.473,0 -6.737,1.352 -9.192,3.808c-5.069,5.069 -5.069,13.316 0,18.385l19.799,19.798c0.195,0.195 0.451,0.293 0.707,0.293c0.256,0 0.512,-0.098 0.707,-0.293l19.798,-19.798Zm-20.506,17.677l-19.092,-19.092c-4.289,-4.289 -4.289,-11.268 -1.77636e-15,-15.557c2.078,-2.078 4.84,-3.222 7.778,-3.222c2.938,0 5.701,1.144 7.778,3.222l2.828,2.829c0.375,0.375 1.039,0.375 1.414,0l2.828,-2.829c2.078,-2.078 4.84,-3.222 7.778,-3.222c2.938,0 5.701,1.144 7.778,3.222c2.078,2.078 3.222,4.84 3.222,7.778c0,2.938 -1.144,5.701 -3.222,7.778l-19.09,19.093Z"></path>
                                            <path d="M21.687,17.858c0,-0.552 -0.448,-1 -1,-1c-3.86,0 -7,3.14 -7,7c0,0.552 0.448,1 1,1c0.552,0 1,-0.448 1,-1c0,-2.757 2.243,-5 5,-5c0.552,0 1,-0.448 1,-1Z"></path>
                                        </g>
                                    </svg>
                                    Favorites
                                </li>
                            </ul>
                        </div>
                        <div className="sidebar-control">
                            <h3>Filter by style</h3>
                            <ul className="list-group">
                                {stylesOptions.map(style =>
                                    <li onClick={() => this.updateStylesValue(style.key)} className={`list-group-item list-group-item-action ${style.key === this.state.stylesFilter ? "active" : ""}`} key={style.key}>
                                        {style.text}
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className={`sidebar-control ${queryProcedure === "showIconHistory" || queryProcedure === "listFavoriteIcons" ? "disabled-section" : ""}`}>
                            <div className="disabler"></div>
                            <h3>Icon collections</h3>
                            <ul className="list-group scrollable-list">
                                {collectionsOptions.map(collection =>
                                    <li onClick={() => this.updateCollectionValue(collection.key)} className={`list-group-item list-group-item-action ${collection.key === this.state.collectionFilter ? "active" : ""}`} key={collection.key}>
                                        {collection.text}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="mobile-sidebar-wrapper">
                        <div class="search-row">
                            <div className="brand-name">
                                <img src={require("../../../../assets/io-logo.png")} alt="IconUncle Logo" />
                            </div>
                            <div className="sidebar-control search-field">
                                <div className="btn-group">
                                    <button className="btn btn-primary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        EN
                                    </button>
                                    <div className="dropdown-menu">
                                        ...
                                    </div>
                                </div>
                                <div className="input-group">
                                    <input type="text" onKeyDown={event => {
                                        if (event.key === 'Enter') {
                                            this.updateFilter()
                                        }
                                    }} onChange={evt => this.updateSearchValue(evt)} className="form-control" placeholder="Search Icon" />
                                    <button className="btn btn-outline-secondary search-button" type="button"></button>
                                </div>
                            </div>
                            <div className="account-controls">
                                <a href="#" onClick={(e) => this.openAccountSettings(e)} className="account-button account"></a>
                                <a href="#" onClick={(e) => this.logout(e)} className="account-button logout"></a>
                            </div>
                        </div>
                        <div class="filter-row">
                            <div class="filter-label">Filter</div>
                            <a onClick={() => this.setState(prevState => ({
                                showFiltersMobile: !prevState.showFiltersMobile
                            }))} className="btn btn-outline-secondary filter-button">
                                {showFiltersMobile ?
                                    "Hide"
                                    : "Show"
                                }</a>
                        </div>
                        <div className={`${showFiltersMobile ? "show" : ""} filter-controls-row`}>
                            <div class="split">
                                <select class="form-select mobile-select" onChange={(event) => this.updateProcedure(event.target.value)} name="" id="">
                                    {session.bookedPlan !== "plus" ?
                                        <option value="searchIcons" key="searchIcons">Free Icons</option>
                                        :
                                        <option value="searchIcons" key="searchIcons">Newest</option>

                                    }
                                    <option value="showIconHistory" key="showIconHistory">History</option>
                                    <option value="listFavoriteIcons" key="listFavoriteIcons">Favorites</option>
                                </select>
                                <select class="form-select mobile-select" onChange={(event) => this.updateStylesValue(event.target.value)} name="" id="">
                                    {stylesOptions.map(style =>
                                        <option value={style.key} key={style.key}>
                                            {style.text}
                                        </option>
                                    )}
                                </select>
                            </div>
                            <div className={`${queryProcedure === "showIconHistory" || queryProcedure === "listFavoriteIcons" ? "disabled-section" : ""}`}>
                                <div className="disabler"></div>
                                <select class="form-select mobile-select" onChange={(event) => this.updateCollectionValue(event.target.value)} name="" id="">
                                    {collectionsOptions.map(collection =>
                                        <option value={collection.key} key={collection.key}>
                                            {collection.text}
                                        </option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                </aside>
                {session.bookedPlan === "free" ?
                    <div className="premium-teaser">
                        <div>
                            <img src="assets/icon-white.svg" alt="" />
                        </div>
                        <div>
                            <FormattedMessage id="subscription.premiumTeaser" />
                            <strong class="upgrade-link" onClick={(e) => this.openSubscribePage(e)}>Upgrade now</strong>
                        </div>
                        <div>
                            <a className="close-option">
                                <Icon iconName={'StatusCircleErrorX'} aria-hidden="true" />
                            </a>
                        </div>
                    </div>
                    : ""}
            </div>
        );
    }
}
