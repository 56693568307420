import * as React
    from "react";
import LoginForm
    from "../Login/LoginForm";
import LoginLoader
    from "../Login/LoginLoader";
import UserSession
    from "../Helper/UserSession";

import SearchBar
    from "./SearchBar";
import TabNavigation
    from "./TabNavigation";
import {
    Icon
} from "@fluentui/react";

import APIWrapper
    from "../Helper/APIWrapper";
import {
    FormattedMessage
} from "react-intl";
import IconHistory
    from "../Helper/IconHistory";
import {
    NavLink
} from "react-router-dom";

const API = 'https://accounts.iconuncle.com/api';
const GET_ICONS_ROUTE = '/procedures/execute';

export default class IconSuggestions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            icon: props.icon,
            differentStyles: [],
            fromCollection: []
        };
    }

    componentDidMount() {
        console.log("Load Suggestions");
        this.loadDifferentStyles()
        this.loadOthersFromCollection()
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            icon: nextProps.icon
        },()=>{
            this.loadDifferentStyles()
            this.loadOthersFromCollection()
        });
    }

    loadDifferentStyles() {

        APIWrapper.request(API + GET_ICONS_ROUTE, "searchIcons", {
            "data": {
                "query": this.state.icon.name["en"],
                "collection": null,
                "style": null
            },
            "meta": {
            "limit": 8
        }
        }, (data) => {
            this.setState({
                differentStyles: data.data.items
            });
        });
    }

    loadOthersFromCollection() {

        APIWrapper.request(API + GET_ICONS_ROUTE, "searchIcons", {
            "data": {
                "collection": this.state.icon.collection.id,
                "style": null
            },
            "meta": {
                "limit": 28
            }
        }, (data) => {
            this.setState({
                fromCollection: data.data.items
            });
        });
    }

    render() {
        const {
            differentStyles,
            fromCollection
        } = this.state;

        return (
            <div className="icon-suggestions ms-Grid">
                <span className="icon-list-headline"><FormattedMessage id="icon.differentStyles" />:</span>
                <ul className="icon-list autoheight">
                    {differentStyles.map(icon =>
                        <li key={icon.id}>
                            {icon.accessibleByUser ?
                                <a onClick={(e) => this.props.showIcon(icon)} className="icon-wrapper">
                                    <span dangerouslySetInnerHTML={{__html: icon.content}} />
                                </a>
                                :
                                <a className="icon-wrapper icon-locked-wrapper">
                                    <span dangerouslySetInnerHTML={{__html: icon.content}} />
                                </a>
                            }
                        </li>
                    )}
                </ul>
                <span className="icon-list-headline"><FormattedMessage id="icon.moreFromCollection" /> <a class="collection-link" onClick={(e) => this.props.openCollection(this.state.icon.collection.id)}>Collection</a>
              :</span>
                <ul className="icon-list autoheight">
                    {fromCollection.map(icon =>
                        <li key={icon.id}>
                            {icon.accessibleByUser ?
                                <a onClick={(e) => this.props.showIcon(icon)} className="icon-wrapper">
                                    <span dangerouslySetInnerHTML={{__html: icon.content}} />
                                </a>
                                :
                                <a className="icon-wrapper icon-locked-wrapper">
                                    <span dangerouslySetInnerHTML={{__html: icon.content}} />
                                </a>
                            }
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}
