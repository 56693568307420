import * as React from "react";
import PropTypes from "prop-types";
import { Spinner, SpinnerSize } from "@fluentui/react";
import {FormattedMessage} from "react-intl";

export default class LoginLoader extends React.Component {
  render() {

    return (
      <section className="ms-welcome__progress ms-u-fadeIn500">
        <Spinner size={SpinnerSize.large} label={<FormattedMessage id="login.signup.loading"/>} />
      </section>
    );
  }
}