import * as React
    from "react";
import LoginForm
    from "../Login/LoginForm";
import LoginLoader
    from "../Login/LoginLoader";
import UserSession
    from "../Helper/UserSession";

import SearchBar
    from "./SearchBar";
import TabNavigation
    from "./TabNavigation";
import {
    Icon,
    Spinner,
    SpinnerSize
} from "@fluentui/react";
import APIWrapper
    from "../Helper/APIWrapper";
import {
    FormattedMessage
} from "react-intl";
import IconHistory
    from "../Helper/IconHistory";

import Cookies
    from 'universal-cookie';
import {
    NavLink
} from "react-router-dom";

const cookies = new Cookies();

const API = 'https://app.iconuncle.com/api';
const GET_ICONS_ROUTE = '/procedures/execute';

export default class Favorites extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            icons: [],
            queryFilter: "",
            styleFilter: null,
            collectionFilter: null,
            session: UserSession.getSession()
        };
    }

    componentDidMount() {
        if (this.state.session.bookedPlan === "plus") {
            this.loadFavorites()
        }
    }


    loadFavorites(increaseOffset) {
        this.setState({isLoading: true});

        if (increaseOffset) {
            this.state.offset = this.state.offset + this.state.limit;
        } else {
            this.state.offset = 0;
        }

        let currentLang = "en";
        if (typeof cookies.get('language') !== "undefined") {
            currentLang = cookies.get('language');
        }

        var accessLevel = null;

        if (UserSession.getSession().bookedPlan === "free" && (this.state.queryFilter === "" && this.state.collectionFilter === null && this.state.styleFilter === null)) {
            accessLevel = "free";
        }

        APIWrapper.request(API + GET_ICONS_ROUTE, "listFavoriteIcons", {
            "data": {
                "query": this.state.queryFilter,
                "collection": this.state.collectionFilter,
                "style": this.state.styleFilter,
                "accessLevel": accessLevel
            },
            "meta": {
                "offset": this.state.offset,
                "language": currentLang,
                "limit": this.state.limit
            }
        }, (data) => {
            this.setState({
                isLoading: false,
                total: data.meta.total
            });

            if (increaseOffset) {
                this.setState({
                    icons: this.state.icons.concat(data.data.items)
                });
            } else {
                this.setState({
                    icons: data.data.items
                });
            }


            console.log(data.data.items);
        });
    }


    importIcon(e, icon) { // param is the argument you passed to the function
        console.log(e);
        e.stopPropagation();
        e.preventDefault();

        IconHistory.addToHistory(icon);

        Office.context.document.setSelectedDataAsync(icon.content, {
            coercionType: Office.CoercionType.XmlSvg,
            imageLeft: 220,
            imageTop: 220,
            imageWidth: 100
        }, function (result) {
            console.log(result);
        });

        console.log("Insert");
    }

    removeAsFavorite(e, icon) { // param is the argument you passed to the function
        console.log(e);
        e.stopPropagation();
        e.preventDefault();

        APIWrapper.request(API + GET_ICONS_ROUTE, "removeFavoriteIcon", {
            "data": {
                "icon": icon
            },
            "meta": null
        }, (data) => {
            this.loadFavorites();
        });
    }

    updateSearchFilter(query, style, collection) {
        console.log(query);

        this.setState({
            queryFilter: query,
            styleFilter: style,
            collectionFilter: collection
        }, () => {
            this.loadFavorites();
        });
    }

    render() {
        let {
            icons,
            isLoading,
            offset,
            session,
            limit,
            total
        } = this.state;
        const {onChangeLanguage} = this.props;
        const moreAvailable = (offset + limit) < total;

        return (
            <div>
                <SearchBar updateSearchFilter={this.updateSearchFilter.bind(this)} onChangeLanguage={onChangeLanguage} /> {session.bookedPlan !== "plus" ?
                <div className="empty-list-teaser">
                    <h3>
                        Premium needed </h3>
                    <p>
                        Unlock all icons, styles, create favorites get the Full App Experience. <NavLink to={"/account"}><a href="#">Upgrade now</a></NavLink>
                    </p>
                </div>
                : ""} {icons.length === 0 && !isLoading && session.bookedPlan === "plus" ?
                <div className="empty-list-teaser">
                    <h3>
                        <FormattedMessage id="favorites.noicons.title" />
                    </h3>
                    <p>
                        <FormattedMessage id="favorites.noicons.description" />
                    </p>
                </div> :
                <ul className="icon-list">
                    {icons.map(icon =>
                        <li key={icon.id}>
                            {icon.accessibleByUser ?
                                <a href={'#icon-detail/' + icon.id} className="icon-wrapper fav-icon">
                                    <span dangerouslySetInnerHTML={{__html: icon.content}} />

                                    <span className="import-badge" onClick={(e) => this.importIcon(e, icon)}>
                                             <Icon iconName={'AddTo'} aria-hidden="true" />
                                        </span>
                                    <span className="remove-icon" onClick={(e) => this.removeAsFavorite(e, icon.id)}><Icon iconName={'StatusCircleErrorX'} aria-hidden="true" /></span>

                                </a>
                                :
                                <a className="icon-wrapper icon-locked-wrapper">
                                    <span dangerouslySetInnerHTML={{__html: icon.content}} />
                                    <span className="icon-locked"> <Icon iconName={'Lock'} aria-hidden="true" /></span>
                                </a>
                            }
                        </li>
                    )} {moreAvailable ?
                    <li className="load-more" onClick={(e) => this.loadIcons(true)}>
                        <FormattedMessage id="icons.loadMore" />
                    </li>
                    : ""
                }
                </ul>
            } {isLoading ?
                <Spinner size={SpinnerSize.large} label={
                    <FormattedMessage id="icons.loading" />} /> : ""}

                <TabNavigation></TabNavigation>
            </div>
        );
    }
}
