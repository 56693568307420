import * as React
    from "react";
import LoginForm
    from "../Login/LoginForm";
import LoginLoader
    from "../Login/LoginLoader";
import UserSession
    from "../Helper/UserSession";

import SearchBar
    from "./SearchBar";
import TabNavigation
    from "./TabNavigation";
import {
    FormattedMessage
} from "react-intl";
import {
    Icon
} from "@fluentui/react";
import APIWrapper
    from "../Helper/APIWrapper";
import IconHistory
    from "../Helper/IconHistory";
import {
    NavLink
} from "react-router-dom";

export default class History extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            icons: [],
            session: UserSession.getSession()
        };
    }

    componentDidMount() {
        if (this.state.session.bookedPlan === "plus"){
            this.loadIcons()
        }
    }

    loadIcons() {
        this.setState({isLoading: true});

        this.setState({
            icons: IconHistory.getHistory()
        });
    }


    importIcon(e, icon) { // param is the argument you passed to the function
        console.log(e);
        e.stopPropagation();
        e.preventDefault();
        IconHistory.addToHistory(icon);

        Office.context.document.setSelectedDataAsync(icon.content, {
            coercionType: Office.CoercionType.XmlSvg,
            imageLeft: 220,
            imageTop: 220,
            imageWidth: 100
        }, function (result) {
            console.log(result);
        });


        console.log("Insert");
    }

    updateSearchFilter(query, style, collection) {
        console.log(query);

        this.setState({
            queryFilter: query,
            styleFilter: style,
            collectionFilter: collection
        }, () => {
        });
    }


    render() {
        let {
            icons,
            session
        } = this.state;
        const {onChangeLanguage} = this.props;

        return (
            <div>
                <SearchBar updateSearchFilter={this.updateSearchFilter.bind(this)} onChangeLanguage={onChangeLanguage} />
                {session.bookedPlan !== "plus" ?
                    <div className="empty-list-teaser">
                        <h3>
                            Premium needed
                        </h3>
                        <p>
                            Unlock all icons, styles, create favorites get the Full App Experience. <NavLink
                            to={"/account"}
                        ><a href="#">Upgrade now</a></NavLink>
                        </p>
                    </div>
                    : ""}
                {icons.length === 0 && session.bookedPlan === "plus"?
                    <div className="empty-list-teaser">
                        <h3>
                            <FormattedMessage id="icons.noicons.title" />
                        </h3>
                        <p>
                            <FormattedMessage id="icons.noicons.description" />
                        </p>
                    </div> :
                    <ul className="icon-list">
                        {icons.map(icon =>
                            <li key={icon.id}>
                                {icon.accessibleByUser ?
                                    <a href={'#icon-detail/' + icon.id} className="icon-wrapper">
                                        <span dangerouslySetInnerHTML={{__html: icon.content}} />

                                        <span className="import-badge" onClick={(e) => this.importIcon(e, icon)}>
                                             <Icon iconName={'AddTo'} aria-hidden="true" />
                                        </span>
                                    </a>
                                    :
                                    <a className="icon-wrapper icon-locked-wrapper">
                                        <span dangerouslySetInnerHTML={{__html: icon.content}} />
                                        <span className="icon-locked"> <Icon iconName={'Lock'} aria-hidden="true" /></span>
                                    </a>
                                }
                            </li>
                        )}
                    </ul>
                }

                <TabNavigation></TabNavigation>
            </div>
        );
    }
}
