import * as React
    from "react";
import LoginForm
    from "../Login/LoginForm";
import LoginLoader
    from "../Login/LoginLoader";
import UserSession
    from "../Helper/UserSession";
import Icons
    from "../LoggedIn/Icons";
import IconDetail
    from "../LoggedIn/IconDetail";
import SearchBar
    from "../LoggedIn/SearchBar";
import { HashRouter, Route } from 'react-router-dom'
import Favorites
    from "./Favorites";
import History
    from "./History";
export default class LoggedIn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    render() {
        const { onChangeLanguage } = this.props;

        return (
            <HashRouter>
                <div className="loggedin-wrapper">
                    <Route
                        exact
                        path='/icons'
                        render={(props) => <Icons {...props}  onChangeLanguage={onChangeLanguage} />}
                    />
                    <Route
                        exact
                        path='/icon-detail/:id'
                        component={IconDetail}
                    />
                    <Route
                        exact
                        path='/favorites'
                        component={Favorites}
                    />
                    <Route
                        exact
                        path='/history'
                        component={History}
                    />
                </div>
            </HashRouter>
        );
    }
}
