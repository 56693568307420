import * as React from "react";
import PropTypes from "prop-types";
import { DefaultButton } from "@fluentui/react";
import Login from "./Login/Login";
import Progress from "./Helper/Progress";
import Cookies from 'universal-cookie';
import UserSession from "./Helper/UserSession";
import {FormattedMessage} from 'react-intl';
import LoggedIn
  from "./LoggedIn/LoggedIn";
const cookies = new Cookies();

/* global console, Office, require */

let userSessionValid = false;
import {
  HashRouter,
  Redirect,
  Route
} from 'react-router-dom'
import Icons
  from "./LoggedIn/Icons";
import IconDetail
  from "./LoggedIn/IconDetail";
import Favorites
  from "./LoggedIn/Favorites";
import History
  from "./LoggedIn/History";
import Auth
  from "./Login/Auth";
import LoginLoader
  from "./Login/LoginLoader";
import Account
  from "./LoggedIn/Account";
console.log("PAGE LOADED");

export default class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.loginSucessfull = this.loginSucessfull.bind(this)
    this.userSessionChecked = false;

    this.state = {
      sessionActive: UserSession.sessionActive(),
    };
  }

  loginSucessfull(){
    console.log(UserSession.getSession());
  }

  componentDidMount() {
    this.setState({
      listItems: [
        {
          icon: "Ribbon",
          primaryText: "Achieve more with Office integration",
        },
        {
          icon: "Unlock",
          primaryText: "Unlock features and functionality",
        },
        {
          icon: "Design",
          primaryText: "Create and visualize like a pro",
        },
      ],
    });


  }


  render() {
    const { title, isOfficeInitialized, onChangeLanguage } = this.props;


    return (
        <HashRouter>
          <div className="loggedin-wrapper">
            {!this.state.sessionActive ?  <Route
                exact
                path='/'
                render={(props) => <Login {...props}  onChangeLanguage={onChangeLanguage} />}
            /> : <Redirect to="/icons" /> }

            <Route
                exact
                path='/auth'
                component={Auth}
            />
            <Route
                exact
                path='/icons'
                render={(props) => <Icons {...props}  onChangeLanguage={onChangeLanguage} />}
            />
            <Route
                exact
                path='/icons/:collection'
                render={(props) => <Icons {...props}  onChangeLanguage={onChangeLanguage} />}
            />
            <Route
                exact
                path='/icon-detail/:id'
                component={IconDetail}
            />
            <Route
                exact
                path='/favorites'
                render={(props) => <Favorites {...props}  onChangeLanguage={onChangeLanguage} />}
            />
            <Route
                exact
                path='/account'
                render={(props) => <Account {...props}  onChangeLanguage={onChangeLanguage} />}
            />
            <Route
                exact
                path='/history'
                component={History}
            />
          </div>
        </HashRouter>
    );
  }
}

App.propTypes = {
  title: PropTypes.string
};
