import Cookies
    from 'universal-cookie';

import UserSession
    from "../Helper/UserSession";
import {
    decode as atob,
    encode as btoa
} from 'base-64'
import {
    config
} from "./Constants";


var APIWrapper = (function () {

    var tokenRefreshActive = false;

    var request = async function (url, procedure, requestData, callback, method) {
       console.log("tokenRefreshActive");
       console.log(tokenRefreshActive);
        if (typeof method === "undefined"){
            method = "POST";
        }

        if (!UserSession.sessionActive()) {
            console.log("inactive");
            window.location.href = "/app.html";
            return null;
        }

        if (UserSession.tokenNeedsRefresh() && !tokenRefreshActive) {
            tokenRefreshActive = true;
            console.log("Token inactive");
            var session = UserSession.getSession();

            var details = {
                'grant_type': 'refresh_token',
                'refresh_token': session.refresh_token
            };
            var formBody = [];
            for (var property in details) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(details[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            fetch("https://accounts.iconuncle.com/oauth/token", {
                method: 'POST',
                body: formBody,
                headers: {
                    'Authorization': 'Basic ' + btoa(config.client_id+':' + session.access_token),
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log("Refresh");
                    console.log(data);
                    if ('error' in data && data.error === "invalid_grant") {
                        console.info("Session was ended here is why")
                        console.error(data.error_description);
                        console.error(data);
                        UserSession.destroy();
                        window.location.href = "/app.html";
                    } else {
                        var session = data;
                        session.last_refreshed = Math.round(Date.now() / 1000);
                        UserSession.setSession(session);
                        tokenRefreshActive = false;
                        console.error("token refrshed");

                        if (method === "post"){
                            post(url, procedure, requestData, callback);
                        }else if (method === "get"){
                            get(url, procedure, requestData, callback);
                        }
                    }
                });

        } else {
            console.log("Token still active");
            if (tokenRefreshActive){
                var checkTokenRefresh = setInterval(function(){
                    if (!tokenRefreshActive){
                        clearInterval(checkTokenRefresh);
                        post(method, url, procedure, requestData, callback);
                    }
                },100);
            }else{
                post(method, url, procedure, requestData, callback);
            }
        }
    };
    var post = function (method, url, procedure, requestData, callback) {
        var session = UserSession.getSession();
        console.log("session.access_token");
        console.log(session.access_token);

        if (method === "POST"){
            fetch(url, {
                method: method,
                body: JSON.stringify({
                    "package": "app",
                    "procedure": procedure,
                    "data": requestData.data,
                    "meta": requestData.meta
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + session.access_token
                }
            })
                .then(response => response.json())
                .then(data => {
                    return callback(data);
                });
        }else{

            fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + session.access_token
                }
            })
                .then(response => response.json())
                .then(data => {
                    return callback(data);
                });
        }
    };

    return {
        request: request
    }

})();

export default APIWrapper;