import Cookies from 'universal-cookie';
const cookies = new Cookies();

var UserSession = (function() {


    var setSession = function(sessionData) {
        localStorage.setItem('userSession', JSON.stringify(sessionData));
    };

    var destroy = function() {
        localStorage.removeItem('userSession');
    };

    var getSession = function() {
        var session = JSON.parse(localStorage.getItem('userSession'));
        return session;
    };
    var tokenNeedsRefresh = function() {
        var session = getSession();

        return (session.expires_in+session.last_refreshed <  Math.round(Date.now() / 1000));
    };

    var sessionActive = function() {
       return localStorage.getItem('userSession') !== null && JSON.parse(localStorage.getItem('userSession')) !== null;
    };

    return {
        setSession: setSession,
        tokenNeedsRefresh: tokenNeedsRefresh,
        sessionActive: sessionActive,
        destroy: destroy,
        getSession: getSession
    }

})();

export default UserSession;