import * as React
    from "react";
import LoginForm
    from "../Login/LoginForm";
import LoginLoader
    from "../Login/LoginLoader";
import UserSession
    from "../Helper/UserSession";
import APIWrapper
    from "../Helper/APIWrapper";

import Sidebar
    from "./Sidebar";
import TabNavigation
    from "./TabNavigation";
import {
    Icon,
    Spinner,
    SpinnerSize
} from "@fluentui/react";
import {
    FormattedMessage
} from "react-intl";
import Cookies
    from 'universal-cookie';
import IconHistory
    from "../Helper/IconHistory";
import IconDetail
    from "./IconDetail";

const cookies = new Cookies();

const API = 'https://accounts.iconuncle.com/api';
const GET_ICONS_ROUTE = '/procedures/execute';
const GET_PASSWORD_ROUTE = '/procedures/execute';

export default class Icons extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            icons: [],
            showIconDetail: false,
            selectedIcon: null,
            queryProcedure: "searchIcons",
            queryFilter: "",
            styleFilter: "1ed075ea-7d9d-61f8-9665-5f415c0489fd",
            offset: 0,
            limit: 100,
            total: 100,
            collectionFilter: null
        };

        this.openIcon = this.openIcon.bind(this)

    }


    componentDidMount() {
        this.loadIcons()
    }


    closeDetailSidebar() {
        this.setState({showIconDetail: false});
    }

    openCollection(collection) {

        if (collection !== null) {
            this.setState({
                collectionFilter: collection,
                queryProcedure: "searchIcons",
                queryFilter: null,
                styleFilter: "1ed075ea-7d9d-61f8-9665-5f415c0489fd",
            }, () => {
                this.loadIcons();
            });
        }
    }


    loadIcons(increaseOffset) {
        this.setState({isLoading: true});

        if (increaseOffset) {
            this.state.offset = this.state.offset + this.state.limit;
        } else {
            this.state.offset = 0;
        }

        var collection = this.state.collectionFilter;
        var style = this.state.styleFilter;

        if (style === "default") {
            style = null;
        }
        if (collection === "default") {
            collection = null;
        }

        let currentLang = "en";
        if (typeof cookies.get('language') !== "undefined") {
            currentLang = cookies.get('language');
        }

        var accessLevel = null;

        if (UserSession.getSession().bookedPlan === "free" && ((this.state.queryFilter === "" || typeof this.state.queryFilter === "undefined") && (collection === null || typeof collection === "undefined"))) {
            accessLevel = "free";
        }

        APIWrapper.request(API + GET_ICONS_ROUTE, this.state.queryProcedure, {
            "data": {
                "query": this.state.queryFilter,
                "collection": collection,
                "style": style,
                "accessLevel": accessLevel
            },
            "meta": {
                "offset": this.state.offset,
                "language": currentLang,
                "limit": this.state.limit
            }
        }, (data) => {
            this.setState({
                isLoading: false,
                total: data.meta.total
            });

            if (increaseOffset) {
                this.setState({
                    icons: this.state.icons.concat(data.data.items)
                });
            } else {
                this.setState({
                    icons: data.data.items
                });
            }


            console.log(data.data.items);
        });
    }

    removeAsFavorite(e, icon) { // param is the argument you passed to the function
        console.log(e);
        e.stopPropagation();
        e.preventDefault();

        if (confirm("Are you sure you want to remove this icon from your favorites?") === true) {
            APIWrapper.request(API + GET_ICONS_ROUTE, "removeFavoriteIcon", {
                "data": {
                    "icon": icon
                },
                "meta": null
            }, (data) => {
                this.loadIcons();
            });
        }

    }

    updateSearchFilter(procedure, query, style, collection) {
        console.log(query);

        this.setState({
            queryProcedure: procedure,
            queryFilter: query,
            styleFilter: style,
            collectionFilter: collection
        }, () => {
            this.loadIcons();
        });
    }

    downloadIcon(e, icon) {
        e.stopPropagation();
        e.preventDefault();

        IconHistory.addToHistory(icon)

        this.getOneTimePassword((password) => {
            window.location = icon.url + "?otp=" + password;
        })
    }

    getOneTimePassword(callback) {

        APIWrapper.request(API + GET_PASSWORD_ROUTE, "createOneTimePassword", {
            "data": null,
            "meta": null
        }, (data) => {
            callback(data.data.password);
        });
    }


    openIcon(icon) {
        if (icon.accessibleByUser) {
            const element = document.getElementById('icon-detail-showcase');
            if (element) {
                element.scrollIntoView({behavior: 'smooth'});
            }
            this.setState({
                selectedIcon: icon,
                showIconDetail: true
            })
        }
    }

    render() {
        let {
            icons,
            showIconDetail,
            selectedIcon,
            isLoading,
            offset,
            limit,
            total
        } = this.state;
        const {onChangeLanguage} = this.props;
        const moreAvailable = (offset + limit) < total;
        console.log(total);

        return (
            <div>
                <Sidebar updateSearchFilter={this.updateSearchFilter.bind(this)} onChangeLanguage={onChangeLanguage} />

                <div class="icon-list-wrapper">
                    {icons.length === 0 && !isLoading ?
                        <div className="empty-list-teaser">
                            <h3>
                                <FormattedMessage id="icons.noicons.title" />
                            </h3>
                            <p>
                                <FormattedMessage id="icons.noicons.description" />
                            </p>
                        </div> :
                        <ul className="icon-list">
                            {icons.map(icon =>
                                <li className={`${icon.accessibleByUser ? "" : "icon-locked"}`} key={icon.id} onClick={() => this.openIcon(icon)}>
                                    <span dangerouslySetInnerHTML={{__html:  icon.content.replace(/width="\S+"|height="\S+"/gi, '')}} /> {icon.accessibleByUser ?
                                    <a href="" onClick={(e) => this.downloadIcon(e, icon)} className="quick-download-button">Download</a>
                                    :
                                    <span className="icon-locked-badge">Premium</span>
                                } {this.state.queryProcedure === "listFavoriteIcons" ?
                                    <a href="" onClick={(e) => this.removeAsFavorite(e, icon.id)} className="remove-from-favorites">Remove</a>
                                    : ""
                                }
                                </li>
                            )} {moreAvailable ?
                            <li className="load-more" onClick={(e) => this.loadIcons(true)}>
                                <FormattedMessage id="icons.loadMore" />
                            </li>
                            : ""
                        }
                        </ul>
                    } {isLoading ?
                    <Spinner size={SpinnerSize.large} label={
                        <FormattedMessage id="icons.loading" />} /> : ""}
                </div>
                <IconDetail icon={selectedIcon} showSidebar={showIconDetail} closeDetailSidebar={this.closeDetailSidebar.bind(this)} openCollection={this.openCollection.bind(this)} showIcon={this.openIcon}></IconDetail>

            </div>
        );
    }
}

