import APIWrapper
    from "./APIWrapper";

const API = 'https://accounts.iconuncle.com/api';
const EXECUTE_ROUTE = '/procedures/execute';

var IconHistory = (function() {

    var addToHistory = function(icon) {
        console.log("Add to history");
        console.log(icon);

        APIWrapper.request(API + EXECUTE_ROUTE, "addHistoryEntry", {
            "data": {
                "icon": icon.id
            },
            "meta": null
        }, (data) => {
            console.log("Added to history");
            console.log(data);
        });
    };

    var getHistory = function(callback) {

        APIWrapper.request(API + EXECUTE_ROUTE, "showIconHistory", {
            "data": null,
            "meta": null
        }, (data) => {
            callback(data.data.items);
        });

        return history;
    };



    return {
        addToHistory: addToHistory,
        getHistory: getHistory
    }

})();

export default IconHistory;